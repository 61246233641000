<template>
 <v-ons-page>
  <div class="about">


    <p style="text-align: center">
      Welcome home.
    </p>
 

    <h3>Coming Soon</h3>
     <a href="#" onclick="testapi()">Test API</a>
    <h3 id="output"></h3>
    <ons-button @click.prevent="router.push('/')">Home</ons-button>
    <br>

  </div>
 </v-ons-page>


</template>
<script>

 window.fn = {};


window.fn.open = function() {
  var menu = document.getElementById('menu');
  menu.open();
};

window.fn.load = function(page) {
  var content = document.getElementById('content');
  var menu = document.getElementById('menu');
  content.load(page)
    .then(menu.close.bind(menu));
};

window.testapi = function(){ 
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function(){
          if (this.readyState == 4) {
            var elmt = document.getElementById("output");
            elmt.innerHTML = this.responseText;
          }
        };

        xhttp.open("PUT", "https://us-central1-terratonepull-c669d.cloudfunctions.net/contacts/linkproperty/relink/", true);
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify({"database":"apt","contact_id":null,"taxrecord_ids":[6943]}
        //xhttp.send(JSON.stringify({"database":"apt","contact_id":13157,"taxrecord_ids":[6943,6944,7673]}
        ));
        xhttp.send();

 
  }

import router from "../router";
export default {
 
  data() {
    return {
      router:router
    }
  } 
    
}

</script>


